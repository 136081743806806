body {
	font-family: Helvetica
}

table {
	border-width: 1;
	border-style: solid
}

td {
	border-right-style: solid;
	border-bottom-style: solid;
	padding-left: 5px;
	padding-right: 5px;
}
h1, h2, h3, h4, .content {
	color: #2B7EC1 ;
}

div {
	  border-style: none;
	  border-width: 2px;
	  border-color: red;
}

ul.sidenav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 170px;
  background-color: #55AF31;
  position: fixed;
  height: 100%;
  overflow: auto;
}

ul.sidenav  div {
	background-color: white;
	width: 170px;
}

ul.sidenav  img {
	width: 150px;
	padding: 10px;
	background-color: white;
}

ul.sidenav li a {
  display: block;
  color: #000;
  padding: 8px 16px;
  text-decoration: none;
}
 
ul.sidenav li a.active {
  background-color: #55AF31;
  color: white;
}

ul.sidenav li a:hover:not(.active) {
  background-color: #555;
  color: white;
}

.content {
  margin-left: 180px;
  padding-top: 180px;
  padding: 1px 16px;
  height: 1000px;
}
@media screen and (min-width: 900px) {
	div.content {width: 650px; padding-top: 165px; padding-left: 50px;}
}

@media screen and (max-width: 900px) {
  ul.sidenav {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  ul.sidenav li a {
    float: left;
    padding: 15px;
  }
  ul.sidenav div {
	  width: 100%;
	  background-color: white;
  }
  
  div.content {margin-left: 0;}
}

@media screen and (max-width: 400px) {
  ul.sidenav li a {
    text-align: center;
    float: none;
  }
  div.content {margin-left: 0;}
}